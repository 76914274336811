<template>
  <RAsyncPagedTable
    :data="data.results"
    :columns="columns"
    :page="page"
    :page-size="pageSize"
    :total-records="data.count"
    @fetch="$emit('fetch')"
    @page-change="$emit('page-change', $event)"
  >
    <template #date="{ item }">
      <td>
        <div class="heavy">
          {{ item.published | formatDateTime('MMM dd, yyyy') }}
        </div>
        <div class="faded">
          {{ item.published | formatDateTime('h:mm a') }}
        </div>
      </td>
    </template>
    <template #bed-bath="{ item }">
      <td>
        {{ item.bedroom_count ? `${item.bedroom_count}BD` : '-' }} /
        {{ item.bathroom_count ? `${item.bathroom_count}BA` : '-' }}
      </td>
    </template>
    <template #title="{ item }">
      <td>
        <template v-if="isFailed(item.status)">
          <span class="faded">{{ item.title }}</span>
        </template>
        <template v-else-if="item.view_url">
          <a target="_blank" :href="item.view_url">{{ item.title }}</a>
        </template>
        <template v-else>
          {{ item.title }}
        </template>
      </td>
    </template>
    <template #status="{ item }">
      <td>
        <RBadge v-if="item.status === 'deleted'" color="secondary">
          Deleted
        </RBadge>
        <RBadge v-if="item.status === 'expired'" color="secondary">
          Expired
        </RBadge>
        <RBadge v-if="item.status === 'flagged'" color="danger">
          Flagged
        </RBadge>
        <RBadge v-if="item.status === 'phone_confirmation_needed'" color="warning">
          Phone Verification
        </RBadge>
        <RBadge v-if="item.status === 'email_confirmation_needed'" color="warning">
          Email Verification
        </RBadge>
        <RBadge v-if="item.status === 'post_volume_limit_reached'" color="warning">
          Posting Limit Reached
        </RBadge>
        <RBadge v-if="item.status === 'posting_too_rapidly_reached'" color="warning">
          Posting Too Rapidly Reached
        </RBadge>
        <RBadge v-if="item.reposted" color="secondary">
          Reposted
        </RBadge>
        <RBadge v-if="item.manual_post" color="primary">
          Posted Manually
        </RBadge>
        <RBadge v-if="item.posted_through_caas" color="info">
          Posted by Rooof
        </RBadge>
      </td>
    </template>
    <template #price="{ item }">
      <td>
        {{ formatPrice(item.price) }}
      </td>
    </template>
    <template #renew_count="{ item }">
      <td class="text-center">
        {{ item.renew_count ? item.renew_count : '-' }}
      </td>
    </template>
    <template #email_leads="{ item }">
      <td class="text-center">
        {{ item.email_leads ? item.email_leads : '-' }}
      </td>
    </template>
    <template #redirect_count="{ item }">
      <td class="text-center">
        {{ item.redirect_count ? item.redirect_count : '-' }}
      </td>
    </template>
  </RAsyncPagedTable>
</template>

<script>
/**
 * PostingActivityTable
 *
 * Displays a list of ads with some basic information about each ad.
 *
 * Events:
 *
 * @fetch: refresh table data
 * @page-change: triggered when the selected page or pageSize are changed
 */
import { RBadge } from '@/components/badges'
import { RAsyncPagedTable } from '@/components/tables'

const FAILURE_STATUSES = ['deleted', 'expired', 'flagged']

export default {
  name: 'PostingActivityTable',
  components: {
    RBadge,
    RAsyncPagedTable
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    features: {
      type: Object,
      required: true
    }
  },
  computed: {
    columns () {
      return [
        {
          key: 'date',
          label: 'Date',
          _style: 'width: 12%'
        },
        {
          key: 'bed-bath',
          label: 'Bed/Bath',
          _style: 'width: 8%'
        },
        {
          key: 'title',
          label: 'Ad Title',
          _style: 'width: 35%'
        },
        {
          key: 'status',
          label: ''
        },
        {
          key: 'price',
          label: 'Price',
          _style: 'width: 8%'
        },
        {
          key: 'account',
          label: 'Account',
          _style: 'width: 15%'
        },
        ...this.features.automaticRenewal ? [
          {
            key: 'renew_count',
            label: 'Renews',
            _style: 'width: 5%'
          }] : [],
        ...this.features.emailHandling ? [
          {
            key: 'email_leads',
            label: 'Emails',
            _style: 'width: 5%'
          }] : [],
        ...this.features.redirectLinks ? [
          {
            key: 'redirect_count',
            label: 'Visits',
            _style: 'width: 5%'
          }] : []
      ]
    }
  },
  methods: {
    /**
     * Format the price to exclude pennies and add dollar sign.
     *
     * @param {Number} price
     * @returns {String}
     */
    formatPrice (price) {
      return price ? `$${price.split('.')[0]}` : '(none)'
    },
    /**
     * Determine if status indicates failure.
     *
     * @param {String} status
     * @returns {Boolean}
     */
    isFailed (status) {
      return FAILURE_STATUSES.includes(status)
    }
  }
}
</script>

<style lang="scss" scoped>
.badge {
  margin: 0 0.2em;
}
</style>
