<template>
  <CRow class="widgets">
    <CCol>
      <ReportingWidget
        :title="{ label: 'Automated Posting', to: { name: 'PostingActivityReport' } }"
        :text="widgetData.posts"
        :locked="!features.automatedPosting && !features.postingService"
        icon="wand"
        info-text="Amount of ads made with PostEngine within the selected time span."
      />
    </CCol>
    <CCol>
      <ReportingWidget
        :title="{ label: 'Automatic Renewal', to: { name: 'PostingActivityReport' } }"
        :text="widgetData.renews"
        :locked="!features.automaticRenewal"
        icon="md-sync"
        info-text="Amount of renewals performed within the selected time span."
      />
    </CCol>
    <CCol>
      <ReportingWidget
        :title="{ label: 'Email Handling', to: { name: 'EmailReport' } }"
        :text="widgetData.emails"
        :locked="!features.emailHandling"
        icon="md-email"
        info-text="Number of emails in the selected time span."
      />
    </CCol>
    <CCol>
      <ReportingWidget
        :title="{ label: 'Website Traffic', to: { name: 'WebsiteTrafficReport' } }"
        :text="widgetData.visits"
        :locked="!features.redirectLinks"
        icon="md-web"
        info-text="Number of unique visitors to your website during the selected time span."
      />
    </CCol>
  </CRow>
</template>

<script>
import { ReportingWidget } from '@/views/reporting/_components'

export default {
  name: 'Widgets',
  components: {
    ReportingWidget
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    features: {
      type: Object,
      required: true
    }
  },
  computed: {
    widgetData () {
      return {
        posts: this.data.metrics.total_posts > 0 ? `${this.data.metrics.total_posts} Posts` : null,
        renews: this.data.metrics.total_renews > 0 ? `${this.data.metrics.total_renews} Renews` : null,
        emails: this.data.metrics.total_emails > 0 ? `${this.data.metrics.total_emails} Emails` : null,
        visits: this.data.metrics.total_redirects > 0 ? `${this.data.metrics.total_redirects} Visits` : null
      }
    }
  }
}
</script>
